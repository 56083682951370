*{
    padding: 0;
    margin: 0;
}

body{
    font-size: 14px;
    background: #f4f4f6;
}
.tmChat{
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.exhibitorChatWidget{
    height: 600px;
    width: calc(100% - 360px);
    position: relative;
}
.chatMainHeader {
    background: #0696d3;
    width: 100%;
    height: 78px;
    top: 0;
}

.message_div {
    width: calc(100% - 40px);
}

.online-user, .online-user2 {
    border-bottom: solid 1px #ededed;
}

.online-user2 span {
    display: block;
}

.online-user2 button {
    height: 100%;
    border: none;
    background: transparent;
    width: 100%;
    outline: none;
}
video{
    background-color: black;
}
.online-user2 img {
    margin-bottom: 4px;
}

.search-bar input[type="text"] {
    border: solid 1px #ededed;
    width: 100%;
    padding: 10px 30px 10px 40px;
    outline: none;
}

.search-bar img {
    position: absolute;
    top: 14px;
    left: 19px;
}
.call-action{
    width: 45px;
    height: 45px;
    display: inline-block;
    margin-right: 15px;
    border-radius: 50%;
    cursor: pointer;
    float: left;
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    text-align: center;
    background-color: white;
    padding: 12px;
}

.calling-bg {
    background-color: #2b2c33;
}

.end-call{
    background-color: #f06b7c;
}
.message-attachments{
    max-width: 260px;
    max-height: 260px;
    overflow: hidden;
    /* width: 100%; */
    min-width: 40px;
    min-height: 60px;
}
.loader-spinner{
    font-size: 25px;
    margin-right: 12px;
}
.user-list-attachment{
    width: 15px;
    margin-right: 5px;
}
.search-times{
    position: absolute;
    top: 10px;
    right: 15px;
    color: rgba(0,0,0,0.4);
    cursor: pointer;
}
.accept-call{
    background-color: #0abf34;
}
.chat-role-active{
    padding: 8px;
    border-bottom: #00adff 4px solid;
}
.custom-shadow {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.user_message {

}
.video-call-end{
    transform: rotate(220deg);
    color: white !important;
    background-color: red !important;
}
.video_call button {
    height: 100%;
    border: none;
    background: transparent;
    width: 100%;
    outline: none;
    font-size: 16px;
}

.user_last_messages .user_img {
    overflow: hidden;
}

.user_last_messages .online-user.active, .user_last_messages .online-user:hover {
    background: #6f6f6f;
    color: #fff;
}
.user_last_messages .online-user.active .name, .user_last_messages .online-user.active .time, .user_last_messages .online-user:hover .time, .unreadmessages-count {
    color: #fff;
}

.user_last_messages .user_message {
    width: calc(100% - 30px);
}

.unreadmessages-count {
    width: 20px;
    height: 20px;
    font-size: 11px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #006792;
    border-radius: 50%;
}

#leftpanel{
    border: 2px solid #ededed;
}
.user_last_messages {
    /* height: calc(100% - 263px); */
    /* height: 100%; */
    overflow-y: auto;
    height: calc(100% - 50px);
}
.localVideoUser{
    top: 0px;
    left: 0px;
}

.chat-widget-messages, .chat-widget-messages-opp {
    margin-bottom: 15px;
}

.user_img {
    border: 1px solid #d8d8d8;
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    color: #fff;
    align-items: center;
    position: relative;
    border-radius: 50%;
    background: #b3b3b3;
    font-weight: 600;

}

.user_status_online {
    width: 11px;
    height: 11px;
    background: #4cda64;
    border: solid 2px #fff;
    top: 0;
    border-radius: 50%;
    left: -2px;
}

.user_status_offline {
    width: 11px;
    height: 11px;
    background: #f34334;
    border: solid 2px #fff;
    top: 0;
    border-radius: 50%;
    left: -2px;
}
.user_status_dnd {
    width: 11px;
    height: 11px;
    background: #5a4dec;
    border: solid 2px #fff;
    top: 0;
    border-radius: 50%;
    left: -2px;
}
.user_status_away {
    width: 11px;
    height: 11px;
    background: #f09e32;
    border: solid 2px #fff;
    top: 0;
    border-radius: 50%;
    left: -2px;
}

.user_status_stable {
    width: 11px;
    height: 11px;
    background: #3a8bf7;
    border: solid 2px #fff;
    top: 0;
    border-radius: 50%;
    left: -2px;
}
.user_img_none {
    width: 40px;
    height: 40px;

}
.tmchatHeader.active {
    background: #006792;
}

.chat-icon button {
    height: 100%;
    border: none;
    background: transparent;
    width: 100%;
    color: #fff;
    outline: none;
    padding: 0 35px;
    font-size: 16px;
}

.video-icon button {
    height: 100%;
    border: none;
    background: transparent;
    width: 100%;
    color: #fff;
    outline: none;
    padding: 0 35px;
    font-size: 16px;
}


.chat-widget {
    box-shadow: 1px 0px 6px rgba(0, 0, 0, 0.1);
    height: 74px;
    position: fixed;
    width: 100%;
}

.name {
    font-weight: 600;
    color: #000;
    font-size: 16px;
}

.user_last_messages .online-user:hover .name {
    color: #fff;
}
.message {
    display: inline-block;
    background: #f2fafc;
    border-radius: 0 20px 20px 20px;
    padding: 10px 25px;
    max-width: 65%;
    box-shadow: 1px 1px 2px #dce9ec;
    text-align: left;
}
.time {
    font-size: 13px;
    color: #888888;
}

.devider span {
    position: absolute;
    top: -11px;
    left: 6%;
    color: #0696d3;
    background: #f4f4f6;
    padding: 0 14px;
}

.chat-widget-messages-opp .time {
    text-align: right;
}

.chat-widget-messages-opp .message {
    background: #d2f0fa;
    border-radius: 20px 0 20px 20px;
    padding: 10px 25px;
    display: inline-block;
}

.tm-username--unread {
    position: relative;
}

.tm-username--unread::after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    background: #4cda64;
    border-radius: 50%;
    margin-left: 10px;
}

.color-lightgreen {
    color: #00b289;
}

.meetings-control--dropdown {
    display: inline-flex;
}

.meetings-cam--arrowbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    width: 20px;
    height: 20px;
    border: 1px solid rgb(153 153 153 / 0.1);
    border-radius: 50%;
    background-color: #f5f5f5;
}

.dropup .dropdown-toggle::after {
    content: none;
}

.meetings-cam--dropdown {
    position: absolute;
    width: 250px;
    bottom: 100%;
    display: inline-block;
    border-radius: 5px;
    overflow: hidden;
    background: #fff;
    margin-bottom: 10px;
}

.settings-subsection {
    font-size: 16px;
}

.device-name {
    font-size: 13px;
    white-space: pre-line;
    width: calc(100% - 30px);
}

.input-area {
    /*border-bottom: solid 1px #888888;*/
    width: 80%;
    position: relative;
}
.file_size {
    color: #878787;
}
.text-black {
    color: #000;
    font-weight: 500;
}
.uploaded-files {
    position: relative;
    width: 140px;
    flex: 0 0 140px;
    margin-right: 15px;
}
.uploaded-files button {
    position: absolute;
    top: 5px;
    right: 8px;
    padding: 0;
    margin-right: 0 !important;
}
.border-radius12 {
    border-radius: 12px;
}
.attachment_area {
    width: 30%;
}
.add-more {
    background: #a6a6a6;
    width: 80px;
    flex: 0 0 80px;
    position: relative;
    margin-right: 15px;
    border-radius: 12px;
}
.attachments-close{
    outline: none;
    border: none;
    width: 43px;
    height: 43px;
    margin: 0 5px;
    background: none;
}
.add-more button {
    width: 100%;
    height: 100%;
}
.uploaded-files {
    /*border-bottom: solid 1px #888888;*/
}
.input-area input[type="text"] {
    border: none;
    background: none;
    width: 100%;
    outline: none;
}
.message-area button, .attachment_area .btn {
    outline: none;
    border: none;
    width: 43px;
    height: 43px;
    margin: 0 5px;
    background: none;
}

.message-area .message-sendbtn {
    position: absolute;
    right: 5px;
    margin: 0;
    width: auto;
    height: auto;
}

.microphone-record-container {
    height: 40px;
}

.microphone-record-container audio {
    height: 100%;
}

.widget {
    /* height: calc(100% - 78px); */
    height: 100%;
    overflow: hidden;
    position: fixed;
    width: 100%;
}
.file-upload{
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
}
.cursor-pointer {
    cursor: pointer;
}
.upload-file-name {
    overflow: hidden;
    word-break: break-all;
}
.list-style-none {
    list-style: none;
}
.video-chat-text-input {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-right: none;
    background: #ddd;
    box-shadow: none;
    outline: none;
    border-color: #ddd;
}
.video-chat-input-group
{
    color:white;border-radius: 50px;cursor:pointer;margin-right: 2px;
}
.video-chat-user{
    background: skyblue;border-radius: 50px;border-top-left-radius: 0px;width: 200px;text-align:justify-all;
}
.video-chat-recepient
{
    background: skyblue;border-radius: 50px;border-top-right-radius: 0px;
}
.video-chat-area{
    margin-bottom: none!important;border-radius: 50px;background: #ddd;padding: 2px;
}
.video-chat-area-wrapper{
    border-top-right-radius: 50px;border-bottom-right-radius: 50px;
}
.video-conference-user{
    color:white;border-radius: 50%;width: 40px;height: 40px;background:#005b96;display:flex;align-items: center;justify-content: center;
}
.video-user {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.video-widget-text{
    position: absolute;top:150px;right: 40px;background: gray;color: white;border-radius: 20px;padding: 0 30px 0 30px;
}
.user-video{
    width: 100%;height:100%;
}
.video-widget{
    background:rgba(0,0,0,0.1);position: absolute;z-index: 1;width: 100%;
}
.volumeSetUp{
    transform: rotate(270deg);
}
.default-control-icon{
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
}
.video-conference-user-info{
    border-radius: 10px;
}
.video-users-list{
    width: 550px;
}
.video-conference-user-details{
    border-radius: 50%;
}
.widget_chat {
    margin-top: 73px;
    height: calc(100% - 140px);
    overflow-y: auto;
}
.exhibitorChatWidget .message-area {
    position: relative;
    width: 100%;
}

.exhibitorChatWidget .widget {
    position: relative;
    height: 100%;
    overflow: visible;
}

.exhibitorChatWidget .chat-widget {
    position: absolute;
    width: 100%;
    top: 0;
}

.exhibitorChatWidget .chat-leftsidebar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.exhibitorChatWidget .user_last_messages {
    /*height: calc(100% - 225px);*/
}

.exhibitorChatWidget .chat_window {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    height: 100%;
    box-shadow: -2px 0px 5px rgb(0 0 0 / 0.2);
}

.exhibitorChatWidget .widget_chat {
    background: #fff;
    height: calc(100% - 145px);
}

.exhibitorChatWidget .input-area {
    width: auto;
    flex-grow: 1;
}

.exhibitorChatWidget .attachment_area {
    width: 30%;
    margin-left: 10px;
}

.exhibitorChatWidget .online-user2 button span {
    font-size: 11px;
}

.exhibitorChatWidget .name {
    font-size: 14px;
}
.message-area {
    position: fixed;
    width: 100%;
    bottom: 0;
    background-color: white;
}
.input-text-area{
    background: #e9eaef;
    border-radius: 20px;
    padding: 5px 40px 5px 17px;;
}

.input-text-area--file-upload {
    border-radius: 0 0 20px 20px;
}

.attachments {
    background: #e9eaef;
    width: 100%;
    position: absolute;
    left: 0;
    padding: 16px;
    bottom: 44px;
    border-radius: 15px 15px 0 0;
    display: flex;
    overflow-x: auto;
}

.input-area img {
    height: max-content;
}

.actions button {
    border: none;
    outline: none;
    background: none;
    padding: 0 10px;
}

.chat-options .dropdown-toggle, .chat-options .dropdown-toggle.active {
    background: none !important;
    color: #0797d3 !important;
    border: none !important;
    padding: 0 !important;
    outline: none !important;
    box-shadow: none !important;
}

.chat-options .dropdown-toggle::after {
    display: none;
}



#userslist .card-body {
    height: 35vh;
    overflow: auto;
    margin: 1px;
    padding: 1px;
}

#userslist .col-6 {
    display: flex;
    align-items: center;
}

#userslist span {
    font-size: 13px;
}

#chat .msg_box {
    height: 35vh;
    overflow: auto;
}

#chat .msg_box img {
    height: 40px;
    width: 40px;
}

.msg_text {
    font-size: 13px;
}

#inactiveusers {
    margin: 0;
    padding: 0;
    overflow: auto;
}

#inactiveusers * {
    margin: 0;
    padding: 0;
}

#inactiveusers div {
    white-space: nowrap;
}

#inactiveusers ul {
    list-style: none;
}

#inactiveusers ul li {
    display: inline-block;
}


.videocontrolicons ul li {
    display: inline-block;
    margin-right: 5px;
}

.videocontrolicons ul li:hover {
    cursor: pointer;
}

.vcicons {
    background: rgba(0, 0, 0, 0.6);
    padding: 10px 0 10px 5px;
    border-radius: 50px;
}

.msg_time {
    font-size: 12px;
}

#mrssidebarbtn,
#mrs {
    display: none;
}

#mrssidebarbtn {
    position: absolute;
    right: 30px;
    top: 10px;
    border-radius: 0px;
    border: 1px solid #ddd;
}

.sidebarbtn {
    margin-right: 10px;
}
.videocontrolicons {
    position: absolute;
    bottom: 50px;
}

/*
.videocontrolicons1 {
    position: absolute;
    bottom: 250px;
}

.videocontrolicons1 ul li {
    display: inline-block;
    margin-right: 5px;
}

.videocontrolicons1 ul lvideoboxi:hover {
    cursor: pointer;
}
*/

.videobox {
    width: 100%;
    height: 100%;
}

.videobox video {
    width: 100%;
    height: 100%;
}

.btn-grad {
    background: linear-gradient(to right, #4776E6 0%, #8E54E9 51%, #4776E6 100%);
    color: white;
}
/* audio Record */

.video-call-user-name{
    line-height: 3;
}
#microphoneRecordBtn{
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    float: left;
    position: relative;
    flex-shrink: 0;
}


.microphone-record-container{
    width: 100%;
    display: block;
}

.microphone-record-container .microphone-stop-btn{
    display: inline-block;
    float: left;
    width: 26px;
    height: 40px;
    /* background-image: url("../images/stop-button.svg");*/
    background-size: 36px;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 12px;
}
.microphone-record-container .microphone-pause-btn{
    display: inline-block;
    float: left;
    width: 40px;
    height: 40px;
    /* background-image: url("../images/pause-btn.svg");*/
    background-size: 36px;
    background-repeat: no-repeat;
    background-position: center;
}
.microphone-record-container .microphone-timer-text{
    display: inline-block;
    float: left;
    font-family: Poppins-Regular;
    font-size: 20px;
    color: #2d3f5a;
    padding-top: 3px;
}
.microphone-record-container .microphone-record-close{
    display: inline-block;
    float: right;
    width: 25px;
    height: 40px;
    /* background-image: url("../images/cancel.svg");*/
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}
.video-userbox {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #fff;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    background: rgba(0,0,0,0.3);
}

.startmeeting-videoon video,.startmeeting-videoon .active-img {
    width: 100%;
    height: 100%;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
}

.participants-users, .handRaiseUsers {
    height: 50%;
}

.status-dropdown {
    display: block;
}

.status-dropdown .dropdown-item {
    color: #000;
    padding: 5px 10px;
    line-height: 1.4;
}

.status-dropdown .dropdown-item:hover {
    background: #dadce0;
}

@media only screen and (min-width: 1200px) {
    .h-full {
        height: 100%;
    }

    .videobox {
        /*height: calc(100% - 100px);*/
    }
}


@media only screen and (max-width: 600px) {

    #sidebarbtn {
        display: none;
    }

    #mrssidebarbtn {
        display: inline-flex;
        position: static;
        top: 0;
        right: 0;
    }

    #aisection section {
        width: 200px !important;
    }

    /*
    #videosection img {
        height: auto;
    }
*/

    .videocontrolicons {
        position: static;
        bottom: 0px;
    }

    .talking p {
        position: static;
        top: 120px !important;
        right: calc(100px - 60px) !important;
        font-size: 12px;
    }
    /*
    #leftpanel {
        margin-top: 10%;
    }
    */

    #userslist .card-body {
        height: 32vh;
    }

    #chat .msg_box {
        height: 32vh;
    }

    .vcicons {
        border-radius: 10px;
        padding: 5px;
    }

    .videocontrolicons {
        position: absolute;
        bottom: 200px;
    }

    .videocontrolicons ul li {
        display: inline-block;
        margin-right: 0px;
        text-align: center;
    }

    .vim {
        border: 1px solid #000;
    }
}

@media only screen and (max-width: 1024px) {

    #sidebarbtn {
        display: none;
    }

    #mrssidebarbtn {
        display: inline-flex;
        position: static;
        top: 0;
        right: 0;
    }

    #aisection section {
        width: 200px !important;
    }

    /*
    #videosection img {
        height: auto;
    }
*/

    .videocontrolicons {
        position: static;
        bottom: 0px;
    }

    .talking p {
        position: static;
        top: 120px !important;
        right: calc(100px - 60px) !important;
        font-size: 12px;
    }

    /*
        #leftpanel {
            margin-top: 10%;
        }*/

    #userslist .card-body {
        height: 32vh;
    }

    #chat .msg_box {
        height: 32vh;
    }

    .vcicons {
        border-radius: 10px;
        padding: 5px;
    }

    .videocontrolicons {
        position: absolute;
        bottom: 200px;
    }

    .videocontrolicons ul li {
        display: inline-block;
        margin-right: 0px;
        text-align: center;
    }

    .vim {
        border: 1px solid #000;
    }
}

.mCSB_scrollTools .mCSB_draggerRail {
    height: auto;
}

.roboto-light {
    font-family: "Roboto-Light", "arial";
}

.roboto-regular {
    font-family: "Roboto-Regular", "arial";
}

.roboto-medium {
    font-family: "Roboto-Medium", "arial";
}

.roboto-mediumitalic {
    font-family: "Roboto-MediumItalic", "arial";
}


.roboto-bold {
    font-family: "Roboto-Bold", "arial";
}

/*input type number*/

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield;
}

.tmchat input[type=file] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 1;
}

.mCSB_container {
    overflow-y: auto;
}

::-webkit-file-upload-button, select {
    cursor: pointer;
}

a:hover {
    text-decoration: none;
}

.form-control {
    height: 50px;
    border-radius: 8px;
    border: 1px solid #dedede;
}

.btn, .dropdown-menu {
    font-size: 12px;
}

.pointer {
    cursor: pointer;
}

.color-000 {
    color: #000;
}

.color-111 {
    color: #111;
}

.color-222 {
    color: #222;
}

.color-333 {
    color: #333;
}

.color-444 {
    color: #444;
}

.color-555 {
    color: #555;
}

.color-666 {
    color: #666;
}

.color-777 {
    color: #777;
}

.color-888 {
    color: #888;
}

.color-999 {
    color: #999;
}

.color-blue {
    color: #0696d3;
}

.bg-blue {
    background-color: #0696d3;
}

.mb-3, .my-3 {
    margin-bottom: 0.5rem!important;
}

.pr-2, .px-2 {
    padding-right: .5rem!important;
    margin: 8px 0px;

}


.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #33333380;
    opacity: 1; /* Firefox */
    font-family: "Roboto-Regular", "arial";
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #33333380;
    font-family: "Roboto-Regular", "arial";
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #33333380;
    font-family: "Roboto-Regular", "arial";
}

a:link, a:visited, a:hover, a:active {
    color: inherit;
}

.c-hamburger {
    display: block;
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 20px;
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: 0;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
}

.c-hamburger span {
    display: block;
    position: absolute;
    top: 9px;
    left: 0px;
    right: 0px;
    height: 2px;
    background: #fff;
}

.c-hamburger span::before, .c-hamburger span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #fff;
    content: "";
}

.c-hamburger--htx span::before, .c-hamburger--htx span::after {
    transition-duration: 0.3s, 0.3s;
    transition-delay: 0.3s, 0s;
}

.c-hamburger--htx span::before {
    transition-property: top, transform;
}

.c-hamburger span::before {
    top: -9px;
}

.c-hamburger span::after {
    bottom: -9px;
}

.c-hamburger--htx.is-active span {
    background: transparent!important;
}

.c-hamburger--htx.is-active span::before {
    top: 0;
    transform: rotate(45deg);
    width: 26px;
    left: 0;
    margin-left: 3px;
}

.c-hamburger--htx.is-active span::after {
    bottom: 0;
    transform: rotate(-45deg);
    width: 26px;
    left: 0;
    margin-left: 3px;
}

.c-hamburger--htx.is-active span::before, .c-hamburger--htx.is-active span::after {
    transition-delay: 0s, 0.3s;
}

.c-hamburger--htx span::before {
    transition-property: top, transform;
}

.c-hamburger--htx span::after {
    transition-property: bottom, transform;
}

.logo-img {
    width: 200px;
}

.navbar-nav .nav-item {
    padding: 5px 15px;
}

.navbar-nav .nav-item.active {
    background-color: #006792;
}

.fullscreen-height {
    height: calc(100% - 70px);
}

.schedule-meetingheading {
    font-size: 16px;
}

.custom-height {
    height: calc(100% - 35px);
}

.meetings-leftbox {
    height: 450px;
    overflow: auto;
}

.meetings-list:hover .meeting-details {
    background-color: #d4f2ff;
}

.meeting-day, .meeting-time {
    font-size: 11px;
}

.meeting-date {
    font-size: 16px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.meeting-details {
    padding: 9px;
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #dedede;
}

.meeting-name {
    width: calc(100% - 90px);
}

.meetings-rightbox {
    padding: 20px 25px;
    border-radius: 8px;
    overflow: auto;
    background-color: #fff;
    border: 1px solid #dedede;
}

.meetings-userslist {
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.05);
}

.status-btn {
    position: absolute;
    top: -2px;
    left: 0;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #4dd965;
    border: 2px solid #ffffff;
}

.meetings-userpic {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #2f2f2f;
    font-size: 16px;
    text-transform: uppercase;
    font-family: "Roboto-Bold", "arial";
    min-width: 45px;
    height: 45px;
    background-color: #e5e5e5;
    border-radius: 50%;
    border: 1px solid #d5d5d5;
}

.custom-btn {
    min-width: 120px;
}

.modal-header, .modal-footer, .modal-content {
    border: none;
}

.modal-title {
    font-size: 20px;
}

.addmeetingModal-userslist {
    height: 150px;
    overflow-y: auto;
}


.addmeetingModal-userslist::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.addmeetingModal-userslist::-webkit-scrollbar-track {
    background: #d6d6d6;
}

/* Handle */
.addmeetingModal-userslist::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.addmeetingModal-userslist::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.addusers-userbox {
    width: calc(100% - 45px);
}

.cancel-btn {
    background-color: #666666;
}

.modal-body .nav-tabs {
    font-size: 14px;
    color: #666;
    border: none;
}

.modal-body .nav-tabs::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* Track */
.modal-body .nav-tabs::-webkit-scrollbar-track {
    background: #d6d6d6;
}

/* Handle */
.modal-body .nav-tabs::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.addusers-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.modal-body .nav-tabs svg {
    width: 18px;
}

.modal-body .nav-tabs .nav-link {
    padding: 10px 0;
    border: none;
    white-space: nowrap;
}

.modal-body .nav-tabs .nav-item {
    margin: 0;
    margin-right: 35px;
}

.modal-body .nav-tabs .nav-link.active {
    border: none;
    border-radius: 0px;
    border-bottom: 2px solid #ff2828;
}

.download-file {
    align-self: flex-end;
    margin: 0 10px;
}
.chat-attachment-type {
    position: relative;
    width: 140px;
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #d2f0fa;
    border-radius: 5px;
}

.chat-attachment-type img {
    width: 70px;
}
.chat-open-attachment-modal .modal-body {
    background: #ececec;
}

.chat-open-attachment-modal .modal-body img {
    max-height: 500px;
}

.chat-attachment-name {
    display: inline-block;
    position: absolute;
    left: 5px;
    bottom: 5px;
    right: 10px;
    text-align: center;
}

.download-file img {
    width: 20px;
}

.addusersModal-tabs {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    border-radius: 50%;
    color: #088fcf;
    background-color: #e9e9ef;
}

.add-users--searchbox::before {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
    content: "\f002";
    color: #cdcdcd;
    height: 100%;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-users--searchbox input {
    height: 40px;
    padding-left: 35px;
    border-radius: 25px;
    border: 1px solid #eeeded;
}

.options-checkbox {
    display: inline-flex;
    margin-bottom: 0;
}

.options-checkbox input {
    position: absolute;
    opacity: 0;
    display: none;
}

.options-checkbox .custom-checkbox, .options-checkbox .custom-radio {
    position: relative;
    display: inline-block;
    min-width: 20px;
    height: 20px;
    margin: 0 10px 0 0;
    border-radius: 2px;
    background-color: #f2f2f2;
    border: 1px solid #666;
    top: 4px;
}

.options-checkbox .custom-radio {
    border-radius: 50%;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-checkbox:after, .custom-radio:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.options-checkbox input:checked ~ .custom-checkbox:after, .options-checkbox input:checked ~ .custom-radio:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.options-checkbox .custom-checkbox:after {
    top: calc(50% - 0px);
    left: calc(50% - 7px);
    transform: rotate(-45deg);
    width: 20px;
    height: 8px;
    border-left: 2px solid #000;
    border-bottom: 2px solid #000;
    background: transparent;
    -webkit-transform-origin:left top;transform-origin:left top;-webkit-animation:checkAnimation .2s;animation:checkAnimation .2s;
}

@-webkit-keyframes checkAnimation {
    0% {
        height:0;
        width:0
    }
    50% {
        height:6px;
        width:0;
    }
    to {
        height:8px;
        width:20px;
    }
}
@keyframes checkAnimation {
    0% {
        height:0;
        width:0
    }
    50% {
        height:6px;
        width:0;
    }
    to {
        height:8px;
        width:20px;
    }
}

.addusers-list {
    height: 250px;
    overflow-y: auto;
}

.addusers-list::-webkit-scrollbar {
    width: 4px;
}

/* Track */
.addusers-list::-webkit-scrollbar-track {
    background: #d6d6d6;
}

/* Handle */
.addusers-list::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.addusers-list::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.addusers-userbox {
    width: calc(100% - 45px);
}

/* start meeting */

.startmeeting-leftsection {
    height: 600px;
    background: #444;
}

.startmeeting-beforescreen {
    height: 100%;
    overflow: hidden;
}

.startmeeting-hostdetails {
    position: absolute;
    top: 30px;
    left: 30px;
    z-index: 1;
    font-size: 20px;
}

.startmeeting-videoon {
    height: 100%;
}

.startmeeting-videoon .startmeeting-hostdetails {
    position: absolute;
}

.startmeeting-hostpic {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 230px;
    height: 161px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 10px;
    overflow: hidden;
}

.startmeeting-hostpic video {
    position: absolute;
    width: 100%;
    height: 100%;
}

.startmeeting-details {
    color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.meeting-details-modal {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.startmeeting-details .heading {
    font-size: 20px;
}

.startmeeting-actionbtns {
    position: absolute;
    bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px;
    background-color: rgba(0,0,0,0.6);
    border-radius: 35px;
}

.startmeeting-talking {
    position: absolute;
    right: 30px;
    top: 30px;
    min-width: 150px;
    text-align: center;
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 25px;
    background-color: rgba(0,0,0,0.5);
}

.startmeeting-videocall {
    max-width: 400px;
    /*max-width: 200px;*/
    left: 50%;
    transform: translateX(-50%);
}

.startmeeting-actionbtns .startmeeting-btn:not(:last-child) {
    margin-right: 15px;
}

.startmeeting-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    background-color: #fff;
    border-radius: 50%;
}

.startmeeting-btn--record {
    background-color: #12a112;
}

.startmeeting-btn--end {
    background-color: #ff2828;
}

.tsl-logo {
    position: absolute;
    left: 30px;
    bottom: 30px;
    opacity: 0.2;
}

.startmeeting-chat {
    position: relative;
    display: inline-block;
    max-width: 350px;
    box-shadow: 0.279px 3.99px 4px rgba(174,175,175,0.25);
}

.startmeeting-chat .heading {
    padding: 20px;
    margin: 0 15px;
    border-bottom: 1px solid #eeeded;
}

.startmeeting-chat--close {
    position: absolute;
    top: 5px;
    right: 5px;
}

.startmeeting-chatbox {
    font-family: "Roboto-Regular", "arial";
    overflow: auto;
    height: calc(100% - 135px);
}

.startmeeting-chatuser {
    min-width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0.279px 3.99px 4px rgba(174,175,175,0.25);
}

.startmeeting-messagetime {
    font-size: 12px;
    margin-bottom: 5px;
}

.startmeeting-chattext--left .startmeeting-chattext, .startmeeting-chattext--right .startmeeting-chattext {
    display: inline-block;
    font-size: 14px;
    padding: 10px 15px;
    min-width: 80px;
    max-width: 90%;
    border-radius: 0 20px 20px;
    box-shadow: 0.279px 3.99px 10.5px rgba(202,201,201,0.1);
    background-color: #f2fafc;
}

.startmeeting-chattext--right {
    text-align: right;
    justify-content: flex-end;
}

.startmeeting-chattext--right .startmeeting-chattext {
    border-radius: 20px 0 20px 20px;
    background-color: #d1f0fa;
}

.chatoptions-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 0;
    color: #999;
}
.chatoptions-btn::after {
    content: none;
}
.startmeeting-controlsbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px;
}
.startmeeting-typemessage {
    width: calc(100% - 50px);
}
.startmeeting-typemessage input {
    height: 40px;
    border: none;
    padding-right: 45px;
    border-radius: 20px;
    background-color: #e9eaef;
}
.startmeeting-sendmessage {
    position: absolute;
    top: 4px;
    right: 5px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    bottom: 4px;
    background-image: linear-gradient(to right, #1269bb , #0696d3);
}
.startmeeting-controlsbox .attachments {
    display: flex;
    align-items: center;
    width: 100%;
}
.startmeeting-nouser-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 137px;
    color: #4c4c4c;
}
.startmeeting-nouser-found .heading {
    font-size: 20px;
}

.startmeeting-userslist {
    height: 200px;
    position: relative;
}
.startmeeting-users-found {
    display: flex;
    overflow: auto;
    flex-wrap: wrap;
}
.startmeeting-usersbox {
    position: relative;
    display: inline-block;
    width: 200px;
    margin: 10px 5px;
}

.startmeeting-users video, .startmeeting-users .user-profile-pic {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.startmeeting-users {
    position: relative;
    height: 150px;
    margin-bottom: 5px;
    border-radius: 20px;
    overflow: hidden;
    background-color: #000;
}

.startmeeting-username {
    font-size: 24px;
    color: #fff;
    text-transform: uppercase;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.startmeeting-usersbox:hover .startmeeting-usersactions {
    display: flex;
    transition: 0.5s ease all;
}

.startmeeting-usersactions {
    position: absolute;
    width: 100%;
    display: none;
    justify-content: space-between;
    padding: 20px;
    bottom: 0;
    transition: 0.5s ease all;
}

.startmeeting-users--actionbtn {
    width: 35px;
    height: 35px;
    background-color: #fff;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/* start meeting */

.startmeeting-userpic {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    color: #fff;
    width: 140px;
    height: 140px;
    background-color: #adadad;
    overflow: hidden;
    border-radius: 50%;
}
.startmeeting-name {
    font-size: 20px;
    color: #0696d3;
}
.startmeeting-volumebtn:hover .speaker-range, .speaker-range:hover {
    display: block;
}
.speaker-range {
    display: none;
    padding-left: 15px;
    transform: rotate(-90deg) translate(0px, 3px);
    top: -70px;
}
.upload-attachmentsbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e9eaef;
}
.upload-attachmentsbox input[type=file]{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 1;
}
.chatMainHeader{
    background: #0696d3;
    width: 100%;
    height: 78px;
    top: 0;
    display: block !important;
}

.add-groupbtn {
    position: absolute;
    z-index: 1;
    color: #fff;
    text-transform: uppercase;
    background: #0696d3;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    bottom: 10px;
    font-size: 15px;
}

.rounded-pill{border-radius:50rem!important}
@media only screen and (min-width: 992px) {
    .conferenceMeeting .startmeeting-screen {
        height: calc(100vh - 200px);
    }  .conferenceMeeting .startmeeting-screen.userslist-active {
        height: 100vh;
    }
    .startmeeting-screen {
        /*height: calc(100vh - 137px);*/
        height: 100vh;
    }

    .startmeeting-leftsection, .startmeeting-chat {
        display: inline-block;
        height: 100%;
    }

    .startmeeting-leftsection {
        width: calc(100% - 300px);
    }
    .startmeeting-leftsection.close-chat {
        width: 100%;
    }
    .startmeeting-chat {
        width: 300px;
    }
}

@media only screen and (min-width: 1200px) {
    .startmeeting-details {
        max-width: 400px;
    }
}

@media only screen and (max-width: 1199px) {
    .meetings-leftbox {
        height: 400px;
    }
}
.conference-video{
    padding-bottom: 56.25%;
    overflow: hidden;
}
@media only screen and (max-width: 991px) {
    .h-100 {
        height: auto!important;
    }

    .startmeeting-leftsection {
        height: auto;
    }

    .startmeeting-hostdetails {
        position: unset;
        padding: 20px;
    }

    .startmeeting-videoon .startmeeting-hostdetails {
        top: 0;
        left: 0;
    }

    .startmeeting-hostpic {
        margin: 0 auto;
    }

    .startmeeting-actionbtns {
        position: unset;
        margin-bottom: 30px;
    }

    .startmeeting-videocall {
        position: absolute;
    }

    .startmeeting-chatbox {
        height: 400px;
    }

    .startmeeting-users-found {
        flex-wrap: nowrap;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .startmeeting-videoon .startmeeting-hostdetails {
        top: 0;
        left: 0;
    }
}

@media only screen and (max-width: 767px) {
    .meetings-leftbox {
        height: 300px;
    }

    .startmeeting-videoon .startmeeting-hostdetails {
        position: unset;
    }

    .startmeeting-videoon .startmeeting-actionbtns {
        bottom: 0;
    }
}

@media only screen and (max-width: 575px) {
    .startmeeting-chat {
        max-width: 100%;
        width: 100%;
    }
}

#addgroupModal .modal-header .close, #groupinfoModal .modal-header .close,
#userInfoModal .modal-header .close {
    position: absolute;
    right: 15px;
    top: -20px;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    opacity: 1;
    text-shadow: none;
}

.info-noimage {
    width: 200px;
    height: 200px;
    margin: 0 auto 15px;
    background: #c3c3c3;
    font-size: 20px;
    color: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.infodetail {
    font-size: 16px;
}

#addgroupModal .form-control, #groupinfoModal .form-control {
    font-size: 14px;
    height: auto;
    border-radius: 15px;
}

.upload-imgbox {
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dedede;
    border-radius: 15px;
}

.participants-list {
    width: 300px;
    background: #fff;
}

.participants-listheading {
font-size: 18px;
    color: #000;
    padding-bottom: 10px;
    border-bottom: 1px solid #e5e5e5;
}

.participant-box {
    padding: 10px;
    border-bottom: 1px solid #e5e5e5;
}

.participants-listheading {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 600;
}

.participants-listbox {
    height: calc(100% - 35px);
    overflow: auto;
}

.participant-img {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    color: #333;
    margin-right: 5px;
    border-radius: 50%;
    border: 1px solid #d2d2d2;
    background: #b5b5b5 var(--img) no-repeat center / cover;
}

.participant-name {
    text-transform: capitalize;
    color: #000;
    font-weight: 600;
}

.group-usersdetails {
    height: 200px;
}

.user-detailsbox {
    width: calc(100% - 60px);
}
.group-notification-wrapper{
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
}.group-notification-wrapper span:nth-child(1){
     display: inline-block;
     margin: 0 auto;
     padding: 3px 10px;
     font-size: 12px;
     color: #e93f7f;
     background: #f4f4f6;
     position: relative;
}
.group-notification-wrapper-span::before {
    z-index: -1;
    position: absolute;
    content: '';
    width: calc(100% + 40px);
    height: 1px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #999;
}
.close-attachments-modal{
    position: absolute;
    right: -20px;
    top: -20px;
    color: #fff;
    text-shadow: none;
    box-shadow: none;
    opacity: 1;
}

.user-role {
    width: 20px;
    height: 20px;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    border-radius: 4px;
    text-transform: uppercase;
    border: 1px solid #666;
}

.user-role--active {
    background: #49cf5f;
    border-color: #49cf5f;
    color: #fff;
}


/* booth Chat css */


.color-000 {
    color: #000;
}

.color-111 {
    color: #111;
}

.color-222 {
    color: #222;
}

.color-333 {
    color: #333;
}

.color-444 {
    color: #444;
}

.color-555 {
    color: #555;
}

.color-666 {
    color: #666;
}

.color-777 {
    color: #777;
}

.color-888 {
    color: #888;
}

.color-999 {
    color: #999;
}

.bg-blue {
    background-color: #1da1f2;
}

.color-blue {
    color: #1da1f2;
}

.boothChatSendMessage{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 1;
}

::-webkit-file-upload-button, select {
    cursor: pointer;
}

body {
    font-size: 14px;
    font-family: "OpenSans-Regular", "arial";
}

.widget-box {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 20px;
    right: 30px;
    cursor: pointer;
    border-radius: 50%;
    /*background: #1da1f2 url('chat.png') no-repeat center;*/
    background-size: 50%;
    transform: rotate(0deg);
    transition: 0.5s ease all;
    z-index: 12;
}
.rounded-pill {
    border-radius: 50rem !important;
    max-width: 100% !important;
}
.tm-chat-company-name{
    font-size: 10px;
}
.widget-detailsbox--close {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: -50px;
}

.widget-detailsbox--close img {
    width: 18px;
}

.widget-detailsbox {
    display: flex;
    position: fixed;
    overflow: hidden;
    bottom: 80px;
    right: 30px;
    width: 0;
    height: 0;
    border-radius: 10px;
    background-color: #f3f7f9;
    border: 1px solid #eeeeee;
    transition: 0.5s ease all;
    transform-origin: bottom;
    filter: drop-shadow(0px 2px 4.5px rgba(0,0,0,0.25));
}

.widget-detailsbox--open {
    z-index: 9;
    height: 450px;
    width: 650px;
    overflow: visible;
}

.widget-messagesection {
    padding: 20px;
    overflow: auto;
    height: calc(100% - 177px);
}

.chatbox-username {
    margin-bottom: 5px;
}

.widget-messagebox {
    display: inline-block;
    padding: 15px;
    border-radius: 4px;
    filter: drop-shadow(0px 3px 1.5px rgba(0,0,0,0.06));
    min-width: 100px;
    max-width: 70%;
    border: 1px solid #eeeeee;
}

.widget-messagebox--left {
    color: #fff;
    background-color: #088fcf;
}

.widget-messagebox--right {
    color: #000;
    background-color: #f3f3f4;
}

.chatbox-message {
    display: flex;
    align-items: flex-end;
}

.chatbox-time {
    margin: 0 10px;
    font-size: 12px;
    color: rgb(0 0 0 / 0.5);
}

.form-control {
    padding: 10px 15px;
    height: 45px;
    width: 100%;
    border-radius: 7px;
    background-color: #ffffff;
    border: 1px solid #d3d3d3;
}

.widget-usersbox {
    display: inline-flex;
    flex-direction: column;
    width: 250px;
    height: 100%;
    border-radius: 10px 0 0 10px;
    overflow: auto;
    background-color: #f3f7f9;
}

.username {
    font-size: 15px;
}

.widget-userslist {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 1px solid rgb(0 0 0 / 0.2);
}

.widget-user--active {
    background: rgb(8 143 207 / 0.1);
}

.widget-userimg {
    font-size: 16px;
    color: #fff;
    font-family: "OpenSans-SemiBold", "arial";
    display: inline-flex;
    align-items:  center;
    justify-content: center;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #f3790f;
}

.widget-rightsection {
    position: relative;
    background: #fff;
    overflow: hidden;
    border-radius: 0 10px 10px 0;
    width: calc(100% - 250px);
}

.chat-details--left, .chat-details--right {
    margin-bottom: 20px;
}

.chat-details--right {
    text-align: right;
}

.chat-details--right .chatbox-message {
    flex-direction: row-reverse;
}

.widget-chatsection {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid rgba(0,0,0,0.1);
}

.widget-chatinputfile {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
}

.widget-chatinputbox {
    flex: 1;
}

.widget-chatinputbox .form-control {
    font-size: 16px;
    padding: 10px;
    height: 45px;
    width: 100%;
    background: transparent;
    border: none;
    border-radius: 0;
}

.widget-chatsendbtn {
    width: 37px;
    height: 37px;
    cursor: pointer;
    margin-left: 15px;
    border-radius: 50%;
    background: #088fcf url(/images/tmChat/send.png) no-repeat center;
    background-size: 16px;
}

.dropdown-menu-right {
    left: auto;
    right: 0;
}
.speakerChatMessage{
height: calc(100% - 85px);
    overflow-y: auto;
}
.speaker-chat-input{
    bottom: 0;
}
.dropdown--open {
    display: block;
}

.handraise-buttons {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
}
.handraise-buttons>img{
    width: 20px;
    height: 20px;
}

.handraise-accept {
    background: #12a112;
}

.handraise-reject {
    background: #ff2828;
}

.handraise-accept, .handraise-reject {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

@media only screen and (min-width: 992px) {
    /* width */
    .widget-usersbox::-webkit-scrollbar, .widget-messagesection::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    .widget-usersbox::-webkit-scrollbar-track, .widget-messagesection::-webkit-scrollbar-track {
        background: #e0e0e0;
    }

    /* Handle */
    .widget-usersbox::-webkit-scrollbar-thumb, .widget-messagesection::-webkit-scrollbar-thumb {
        background: #a6a6a6;
    }

    /* Handle on hover */
    .widget-usersbox::-webkit-scrollbar-thumb:hover, .widget-messagesection::-webkit-scrollbar-thumb:hover {
        background: #8e8d8d;
    }
}

@media only screen and (max-width: 991px) {
    .widget-detailsbox--open {
        height: calc(85% - 150px);
    }
}

@media only screen and (max-width: 767px) {
    .widget-detailsbox--open {
        width: 100%;
        right: 0;
        flex-direction: column;
    }

    .widget-usersbox {
        flex-direction: row;
        width: 100%;
        height: 71px;
        border-radius: 10px 10px 0 0;
    }

    .widget-rightsection {
        border-radius: 0;
        width: 100%;
        height: calc(100% - 71px);
    }
}

/** Chat new Header Start **/

.chat-header{
    height: 50px;
    background-color: #808080;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    line-height: 50px;
}
.chat-header .back-btn{
    display: inline-block;
    width: 20px;
    height: 20px;
    float: left;
    margin-left: 20px;
    cursor: pointer;
}
.conversation-back-btn{
    display: inline-block;
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 10px;
    cursor: pointer;
    margin-top: 10px;
}
/** Chat new Header End **/

.h-100{
    height: 100% !important;
}