.mainHeader{
    /* background: transparent; */
    /* background: linear-gradient(0deg, rgba(6,150,211,1) 0%, rgba(9,136,207,1) 41%, rgba(11,134,202,1) 68%, rgba(15,93,176,1) 100%); */
    display: flex;
    justify-content: center;
    padding: 15px;
    flex-direction: column;
    flex-wrap: wrap;
}

.mainHeader .logo {
    margin: 0 auto 15px;
}
.mainHeader p {
    font-size: 20px;
}

.inputIco {
    width: 40px;
    height: 40px;
    background: rgb(6,150,211);
    background: linear-gradient(
0deg, rgba(6,150,211,1) 0%, rgba(9,136,207,1) 41%, rgba(11,134,202,1) 68%, rgba(15,93,176,1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    left: 5px;
    top: 4px;
}

.inputIco img {
    width: 20px;
}

.customInput input {
    width: 100%;
    padding: 12px 15px;
    padding-left: 50px;
    border: solid 2px #dfdfdf;
    border-radius: 50px;
}

.customInput {
    position: relative;
}
.customBtn {
    background: rgb(6,150,211);
    background: linear-gradient( 
0deg, rgba(6,150,211,1) 0%, rgba(9,136,207,1) 41%, rgba(11,134,202,1) 68%, rgba(15,93,176,1) 100%);
    width: 100%;
    border: none;
    color: #fff;
    padding: 11px 0;
    border-radius: 50px;
}
.twoSideList {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    
}

.grey-box{
    padding: 15px;
    background-color: #f5f5f5;
}

.twoSideList .card {
    /* width: calc(100%/2 - 8px); */
    width: 100%;
    margin-bottom: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.twoSideList .card img {
    max-width: 95%;
}
.border-none{
    border: none !important;
}
.box-shadow{
    box-shadow: 0 0 20px #00000017;
}
.halfBtn{
    width: calc(100%/2 - 8px);
    text-align: center;
    color: #fff !important;
}
.scroller{
    padding: 0 15px;
}
.scroller .slick-slide img {
    width: 100%;
    height: 50px;
    object-fit: contain;
    padding: 5px;
}
.scroller .slick-slide{
    padding:5px 2px 0;
}
.scroller .card{
    margin: 0;
}
.scroller .slick-next:before, .scroller  .slick-prev:before{
    color: #000;
}
.pageHeading {
    position: relative;
}

.backBtn {
    position: absolute;
    left: 0;
    top: 7px;
    cursor: pointer;
}
.borderList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.borderList li {
    padding: 7px 0;
    border-bottom: solid 1px #cccccc69;
    display: flex;
    align-items: center;
    position: relative;
}

.userPic img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border: solid 1px #cccccc69;
    border-radius: 50%;
}

.userName {
    margin-left: 10px;
    font-size: 18px;
}
.rightCorner{
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 20px;
    color: #afafaf;
}

.listInline {
    display: flex;
    align-items: center;
}

.content h3 {
    font-size: 16px;
    font-family: "OpenSans-Regular", "arial";
    font-weight: 600;
    margin: 0 0 5px;
}

.content h5 {
    font-size: 13px;
    margin: 0;
    font-family: "OpenSans-Regular", "arial";
}
.unOrderList {
    margin: 0;
    padding: 0 0 0 15px;
    list-style-type: decimal;
    line-height: 1.8;
}
ul.listInline {
    list-style-type: none;
    gap: 15px;
    font-size: 18px;
}
.fullWidth{
    /* width: 100%; */
    display: block;
    margin: 0 auto;
    max-width: 100%;
}
.nameOnly .userName {
    font-size: 16px;
}

.nameOnly .rightCorner {
    top: 8px;
}

.nameOnly li {
    padding: 10px 0;
}
.maroonBtn {
    background: #e31424 !important;
}

/*Exhibit Hall Page*/
section.signleScreen.paddingTop {
    padding-top: 250px !important;
    background-position: center !important;
}

.exhibitHallBxInr {
    padding: 20px 30px;
    background: #fff;
    text-align: center;
    /* max-height: 100vh; */
    /* overflow: auto; */
    /* min-height: 200px; */
}

.exhibitHallBxInr .sponsersList img {
    height: 60px;
    max-width: 100%;
    object-fit: contain;
}

.sponsersList {
    margin: 10px 0 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.sponsersList a {
    display: inline-block;
    padding: 10px;
    width: calc(100%/4 - 20px);
    border: solid 1px #e6e6e6;
    margin: 0 10px 20px;
}
/*End Exhibit Hall Page*/

.loaderWrp{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background:  rgba(0, 0, 0, 0.7);
    z-index: 999999999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loaderWrp img {max-width: 100px;}
.loaderWrp ~ .brokenLink { display: none; }

.table.maps-page td{
    border-top: none !important;
    border-bottom: 1px solid #dee2e6;
}

.table.maps-page td a{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
}

.table.maps-page td span i{
    color: #0a88cd;
}

.card embed{
    max-width: 100%;
}

/* Splash Screen */
.splashWrp{
    border-top: solid 5px #0099b1;
}

.splashWrp .splashListWrp{
    padding-bottom: 20px;
}

.splashWrp .sponsersSlideBx{
    padding-top: 20px;
    border-top: 1px solid #ddd;
}

.splashBx a{
    background: #fbb613;
}

.splashListWrp .splashList:nth-child(2) a {
    background: #0099b1;
}
/* End Splash Screen */

/* Dashboard Screen Session Documents */
.selectionBx {
    min-width: 40%;
    padding: 30px 30px 15px;
    background: #fff;
    /* box-shadow: 0 0 20px rgb(0 0 0 / 75%); */
}

.selectionBx select {
    border: solid 1px #ccc;
    width: 100%;
    padding: 12px 5px;
    border-radius: 0;
}

.selectionBx .form-group button, .selectionBx .form-group a {
    width: 100%;
    border: none;
    background: #0099b1;
    color: #fff;
    padding: 15px;
    font-size: 16px;
    transition: all 500ms ease;
    display: block;
    text-align: center;
}

.selectionBx .form-group button:hover, .selectionBx .form-group a:hover {
    background: #f39911;
    transition: all 500ms ease;
}
/*End Dashboard Screen Session Documents */

/* Exhibitor Profile Pic */
.exhibitor-profile-pic{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    justify-content: flex-end;
}

.exhibitor-profile-pic img {
 width: 150px;
 height: 150px;
 border-radius: 50%;
 object-fit: cover;
 border: solid 1px #e8e8e8;
 padding: 10px;
}

@media only screen and (max-width:991px){
     /*Exhibit Hall Page*/
    .signleScreen{height: auto; background: #fff !important;}
    .sponsersList a {width: calc(100%/2 - 10px);margin: 5px;padding: 10px;}
    .exhibitHallBxInr {padding: 0;}
    .mainHeader .logo img {max-width: 100%;}
    *{box-sizing: border-box;}
    /*End Exhibit Hall Page*/
}

@media (max-width: 700px) {
    .mainHeader p {font-size: 16px;}
}

@media (max-width: 620px) {
  .splashList{
      margin-bottom: 15px;
  }
  .exhibitor-profile-pic{
    justify-content: center;
  }
}
.chat-btn{
    width: 40px;
    height: 40px;
    display: inline-block;
    position: absolute;
    right: 20px;
    z-index: 999;
    top: 85px;
}
.chat-btn.unread-count:after{
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    background-color: #ff9800;
    display: inline-block;
    z-index: 99999;
    content: '';
    border-radius: 50%;
}











