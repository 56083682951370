.exhibitorBoothSec3 .exhibitWallBackground {
    left: 17%;
    top: 30.5%;
    border-radius: 0px;
    height: 290px;
    width: 405px;
    transform: translate(0, 0);
}

.exhibitorBoothSec3 .topInputHolder {
    border: solid 4px #555;
}

.exhibitorBoothSec3 .exhibitVideo.companyVideo {
    top: 215px;
    left: 210px;
    border-radius: 0px;
    width: 360px;
    height: 145px;
}

.exhibitorBoothSec3 .exhibitVideo.prodDemoVideo, .exhibitorBoothSec3 .exhibitVideoWideBx {
    left: 210px;
    top: 370px;
    border-radius: 0px;
    width: 170px;
}

.exhibitorBoothSec3 .exhibitVideoWideBx {
    left: 400px;
}

.exhibitorBoothSec3 .exhibitCompanyBio.topInputHolder {
    right: 220px;
    top: 255px;
    height: 115px;
    box-shadow: 0 0 15px #00000047;
}
.exhibitorBoothSec3 .boothContacts {
    border-radius: 0px;
    right: 190px;
    width: 180px;
    height: 115px;
    bottom: 37px;
    border: solid 4px #555;
}

.exhibitorBoothSec3 .exhibitCompanyLogo {
    top: 90px;
    left: 190px;
    width: 780px;
    border: solid 4px #555;
}

.exhibitorBoothSec3 .contactFollowups {
    left: -5px;
    border: solid 4px #555;
    bottom: 30px;
}
.exhibitorBoothSec3 .exhibitMarketingDoc.topInputHolder {
    left: auto;
    right: -130px;
    bottom: 0;
    top: auto;
    height: 310px;
}
.exhibitorBoothSec3 .boothColorPicker{top:20%;}


@media only screen and (max-width:1700px) and (min-width:1366px){
    .exhibitorBoothSec3 .exhibitWallBackground {
        width: 335px;
        height: 247px;
    }
    
    .exhibitorBoothSec3 .exhibitVideo.companyVideo {
        left: 170px;
        top: 180px;
        width: 300px;
        height: 125px;
    }
    
    .exhibitorBoothSec3 .exhibitVideo.prodDemoVideo, .exhibitorBoothSec3 .exhibitVideoWideBx {
        width: 145px;
        top: 315px;
        left: 170px;
    }
    
    .exhibitorBoothSec3 .exhibitVideoWideBx {
        left: 325px;
    }
    
    .exhibitorBoothSec3 .exhibitCompanyLogo {
        width: 700px;
        left: 155px;
        top: 70px;
    }
    
    .exhibitorBoothSec3 .exhibitCompanyBio.topInputHolder {
        top: 200px;
        right: 180px;
    }
    
    .exhibitorBoothSec3 .boothContacts {
        height: 100px;
        bottom: 25px;
        right: 140px;
    }
    
    .exhibitorBoothSec3 .boothColorPicker {
        top: 13%;
        right: -210px;
    }
}