/**exhibitor-booth**/
.exhibitorBoothSec2 .exhibitorBoothWrapper {
    width: 1100px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    top:425px;
    transform: translate(-50%, -50%);
    z-index: 9;
}
.exhibitorBoothSec2 .attendeeExhibitor .exhibitorBoothWrapper { top:450px }
.exhibitorBoothSec2 .exhibitCompanyLogo, .exhibitorBoothSec2 .topInputHolder, .exhibitorBoothSec2 .exhibitVideo, .exhibitorBoothSec2
.contactFollowups, .exhibitorBoothSec2 .boothContacts {
    position: absolute;
    left: 300px;
    top: 76px;
    width: 550px;
    height: 94px;
    background: #2d10a8;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    z-index: 9;
    font-family: 'Frutiger';
    cursor: pointer;
    border: solid 3px #222;
}
.exhibitorBoothSec2 .exhibitCompanyLogo {
    background: #fff;
    color: #000;
    padding: 5px 0;
}
.exhibitorBoothSec2 .exhibitCompanyLogo .hover-overlay{ opacity: 0; z-index: -1; }
.exhibitorBoothSec2 .exhibitCompanyLogo:hover .hover-overlay {
    top: 50%;
    opacity: 1; z-index: 1;
}
.exhibitorBoothSec2 .exhibitorBoothFrame {
    position: relative;
    z-index: 5;
}

.exhibitorBoothSec2 .exhibitWallBackground {
    position: absolute;
    left: 52.4%;
    top: 63.7%;
    width: 500px;
    height: 330px;
    transform: translate(-50%, -50%);
    z-index: 9;
    background: #ccc;
    border-radius: 0;
}
.exhibitorBoothSec2 .topInputHolder {
    top: 345px;
    left: 37px;
    width: 155px;
    height: 285px;
    font-size: 15px;
    padding: 5px;
    line-height: 1.3;
 }
 .exhibitorBoothSec2 .exhibitCompanyBio.topInputHolder {
    left: auto;
    right: -14px;
}
.exhibitorBoothSec2 .exhibitCompanyLogo input, .exhibitorBoothSec2 .topInputHolder input, .exhibitorBoothSec2 .exhibitVideo input, .exhibitorBoothSec2 .contactFollowups input, .exhibitorBoothSec2 .boothContacts input {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}
.exhibitorBoothSec2 .exhibitCompanyLogo .uploadPlaceholder,.exhibitorBoothSec2  .exhibitVideo .uploadPlaceholder {color:#000; width: 100%;}
.exhibitorBoothSec2 .uploadPlaceholder img {
   /*  max-height: 70px; */
   border-radius: 0px;
}
.exhibitorBoothSec2 .exhibitCompanyLogo span {
    margin-left: 10px;
}
.exhibitorBoothSec2 .exhibitVideoWideBx {
    position: absolute;
    left: 590px;
    top: 481px;
    z-index: 9;
    width: 160px;
    height: 95px;
    line-height: 1.3;
    border: solid 2px #000;
    background: rgba(254,254,255,1);
    background: -moz-linear-gradient(top, rgba(254,254,255,1) 0%, rgba(246,246,246,1) 27%, rgba(229,239,255,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,254,255,1)), color-stop(27%, rgba(246,246,246,1)), color-stop(100%, rgba(229,239,255,1)));
    background: -webkit-linear-gradient(top, rgba(254,254,255,1) 0%, rgba(246,246,246,1) 27%, rgba(229,239,255,1) 100%);
    background: -o-linear-gradient(top, rgba(254,254,255,1) 0%, rgba(246,246,246,1) 27%, rgba(229,239,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(254,254,255,1) 0%, rgba(246,246,246,1) 27%, rgba(229,239,255,1) 100%);
    background: linear-gradient(to bottom, rgba(254,254,255,1) 0%, rgba(246,246,246,1) 27%, rgba(229,239,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefeff', endColorstr='#e5efff', GradientType=0 );
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 15px;
    cursor: pointer;
    overflow: hidden;
    font-family: 'Frutiger';
}

.exhibitorBoothSec2 .exhibitVideo {
    left: 410px;
    top: 480px;
    height: 95px;
    width: 160px;
    font-size: 15px;
    line-height: 1.3;
    border: solid 2px #000;
    background: rgba(254,254,255,1);
    background: -moz-linear-gradient(top, rgba(254,254,255,1) 0%, rgba(246,246,246,1) 27%, rgba(229,239,255,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,254,255,1)), color-stop(27%, rgba(246,246,246,1)), color-stop(100%, rgba(229,239,255,1)));
    background: -webkit-linear-gradient(top, rgba(254,254,255,1) 0%, rgba(246,246,246,1) 27%, rgba(229,239,255,1) 100%);
    background: -o-linear-gradient(top, rgba(254,254,255,1) 0%, rgba(246,246,246,1) 27%, rgba(229,239,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(254,254,255,1) 0%, rgba(246,246,246,1) 27%, rgba(229,239,255,1) 100%);
    background: linear-gradient(to bottom, rgba(254,254,255,1) 0%, rgba(246,246,246,1) 27%, rgba(229,239,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fefeff', endColorstr='#e5efff', GradientType=0 );
    border-radius: 0;
    overflow: hidden;
}
.exhibitorBoothSec2 .exhibitVideo.prodDemoVideo {
    left: 410px;
}
.exhibitorBoothSec2 .exhibitVideo.companyVideo {
    top: 310px;
    width: 340px;
    height: 150px;
}
.exhibitorBoothSec2 .companyVideo, .exhibitorBoothSec2 .prodDemoVideo, .exhibitorBoothSec2 .exhibitVideoWideBx {
    border: solid 2px #ccc;
    border-radius: 0;
}
.exhibitorBoothSec2 .contactFollowups {
    width: 144px;
    height: 170px;
    top: auto;
    bottom: 74px;
    left: 205px;
    font-size: 15px;
    line-height: 1.3;
}
.exhibitorBoothSec2 .boothContacts {
    width: 144px;
    height: 170px;
    left: auto;
    top: auto;
    bottom: 76px;
    right: 160px;
    font-size: 15px;
    line-height: 1.3;
    border-radius: 0;
    padding: 10px;
 }
 .exhibitorBoothSec2 .boothColorPicker {
    position: absolute;
    top: 45%;
    right: -140px;
    left: auto;
    background: #fff;
    padding: 20px 20px 15px;
    border-radius: 00px;
    box-shadow: 0 0 10px #00000038;
    z-index: 999999;
}

.exhibitorBoothSec2 .boothColorPicker label {
    font-size: 15px;
    margin: 0;
    font-family: 'Frutiger';
}

.exhibitorBoothSec2 .colorCircle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #000;
    margin-left: 15px;
    cursor: pointer;
}

.exhibitorBoothSec2 .colorLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.exhibitorBoothSec2 .exhibitFormSubmit {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -70px;
    text-align: center;
}

.exhibitorBoothSec2 .exhibitFormSubmit button {
    outline: none;
    padding: 12px 30px;
    background-image: linear-gradient(to bottom, #004fa2, #054696, #083d8b, #09347f, #092c74);
    border: solid 3px #092c74;
    color: #fff;
    border-radius: 0px;
    box-shadow: 0 0 10px #00000045;
    transition: all 500ms ease;
    font-family: 'Frutiger';
    font-size: 18px;
}
.exhibitorBoothSec2 .exhibitFormSubmit button:hover{background-image: linear-gradient(to bottom, #004fa2, #054696, #083d8b, #09347f, #092c74); transition: all 500ms ease;    border: solid 3px #03235d;}
.exhibitorBoothSec2 .exhibitFormSubmit a {
    color: #fff;
    text-decoration: none;
}
.exhibitorBoothSec2 .colorChart {
    position: absolute;
    right: 40px;
    top: -100px;
    z-index: 9;
}
.exhibitorBoothSec2 .colorChart i.fa {
    font-size: 24px;
    position: absolute;
    right: -10px;
    top: -10px;
    color: #000;
    border-radius: 50%;
    line-height: 1;
    width: 23px;
    height: 23px;
    background: #fff;
    text-align: center;
    line-height: 23px;
    cursor: pointer;
}
@media only screen and (max-width:1700px) and (min-width:320px){
    .bg13{background-position: 0 0px;}
}
@media only screen and (max-width:1700px) and (min-width:1366px){
    .exhibitorBoothSec2 .boothContacts {
        right: 190px;
    }
    
    .exhibitorBoothSec2 .boothColorPicker {
        right: -140px;
        top: 24%;
    }
    
    .exhibitorBoothSec2 .exhibitCompanyBio.topInputHolder {
        right: 30px;
        top: 323px;
    }
    
    .exhibitorBoothSec2 .topInputHolder {
        top: 325px;
    }
    
    .exhibitorBoothSec2 .exhibitWallBackground {
        left: 50%;
    }
    
    .exhibitorBoothSec2 .exhibitVideo.companyVideo {
        left: 385px;
        top: 285px;
    }
    
    .exhibitorBoothSec2 .exhibitVideo.prodDemoVideo {
        left: 385px;
        top: 450px;
    }
    
    .exhibitorBoothSec2 .exhibitVideoWideBx {
        top: 450px;
        left: 565px;
    }
}