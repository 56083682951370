.peer-widget {
    position: relative;
    width: 100%;
    height: 500px;
    display: inline-block;
}

.lounge-chat-widget {
    position: relative;
    height: calc(100% - 40px);
}

.widget-heading {
    background: #f39911;
    color: #fff;
}

.widget-chatheading {
    padding-bottom: 10px;
    color: #1a6797;
    font-size: 18px;
    border-bottom: 1px solid #ededed;
}
.sessionChatLoungeBx{
    position: absolute;
    right: 4%;
    top: 140px;
    width: 30%;
}
.peer-detailsbox {
    height: calc(100% - 88px);
    overflow: auto;
}

/* width */
.peer-detailsbox::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.peer-detailsbox::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.peer-detailsbox::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.peer-detailsbox::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.peer-box {
    margin-right: 5px;
}

.peer-box:nth-child(2n) {
    background: #f0f0f2;
}

.peer-img {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    margin-right: 20px;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    background: #b1c2b9;
    border-radius: 50%;
}

.peer-details {
    width: calc(100% - 120px);
}

.peer-name {
    color: #222;
    font-size: 14px;
    text-transform: capitalize;
}

.peer-message {
    color: #888;
    font-size: 14px;
}

.message-time {
    white-space: nowrap;
    color: #888;
    font-size: 13px;
}

.peer-inputbox {
    width: calc(100% - 50px);
}

.peer-inputbox .send-message-input {
    position: relative;
    padding: 5px 50px;
    height: 45px;
    border: 1px solid #ededed;
    border-radius: 25px;
}
.emoji-mart-preview{
    display: none !important;
}
.emoji-box {
    display: inline-block;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    z-index: 1;
    background:none
}

.file-box {
    display: inline-block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0;
    z-index: 1;
    width: 30px;
    background: none;
}

input[type=file] {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 1;
}

.peersend-btn {
    height: 45px !important;
    width: 45px !important;
    margin-left: 10px !important;
    display: inline-flex !important;
    justify-content: center !important;
    align-items: center !important;
    background: #0696d3 !important;
    border-radius: 50% !important;
    z-index: 999  !important;

}